/*
  Note: DO NOT edit .less files, it will override this file and will changes required CSS classes
  Any modification should be made here or custom CSS file import on specific components
*/

/* 
  Sign in settings top banner
  this changes the main layout top margin since the height of the
  header changes from 70px to 110px with the added banner of 40px height
*/

/* push header down */
.ant-layout:has(.cm-banner) .app-header {
  margin-top: 40px;
}

/* push main content down */
.ant-layout:has(.cm-banner) .app-content {
  margin-top: 110px; 
}

/* push side nav down */
.ant-layout:has(.cm-banner) .side-nav {
  height: calc(100vh - 110px);
  top: 110px;
}

/* only on dashboard page push affix down */
.ant-layout:has(.cm-banner) .dashboard .ant-affix {
  top: 110px !important;
}

/* only on dashboard page push affix down */
.ant-layout .dashboard .ant-affix {
  z-index: 999;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

/* this CSS rule applies to the Ansi component where we show STD Out/Err logs */
code {
  color:white !important; 
  background-color: black !important;
  border: none !important;
}

.ansi-bold {
  font-weight: bold;
}

.ansi-green-fg {
  color:rgba(0,187,0);
}

.ansi-red-fg {
  color: rgb(187, 0, 0);
}

.ansi-bright-black-fg {
  color: rgb(85, 85, 85);
}

.ansi-yellow-fg {
  color: rgb(187, 187, 0);
}

.ansi-italic {
  font-style: italic;
}

.ansi-dim {
  color: rgb(119, 119, 119);
}
.ansi-palette-33-fg {
  color: rgb(70, 90, 132);
}

.ansi-palette-37-fg {
  color: rgb(83, 115, 115);
}

.ansi-palette-166-fg {
  color: rgb(137, 105, 81);
}

.ansi-blue-fg {
  color: rgb(49, 62, 198);
}

.ansi-blue-bg {
  background-color: #1C64F2;
}

.continue-deployment-button {
  background: green;
}

.cm-avatar {
  background-color: #7265e6;
}

.fullSizeSpinner {
  width: 100%;
  display: flex;
  justify-content: center; 
}

/* antd table row class when filtering is set */
.filtered-table td {
  background-color: rgb(254 249 195);
}

/* Contorl Monkey Rocket Loader with overlay */
.cm-rocket-loader-overlay {
  position: fixed;
  background-color: rgb(203 213 225); 
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.25;
}

.cm-rocket-loader-modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}

.cm-rocket-loader-text {
  padding: 5px;
  border-radius: 5px;
  background: linear-gradient(270deg, rgb(26, 172, 202) 25%, rgba(44,99,249,1) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-weight: 500;
}

.cm-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.cm-loader {
  position: relative;
  display: flex;
}

.cm-loader span {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: #eaeef0;
  border: 6px solid #eaeef0;
  border-radius: 50%;
  box-shadow: -8px -8px 15px rgba(255, 255, 255, 1),
              8px 8px 25px rgba(255, 255, 255, 0.2);
  overflow: hidden;
}

.cm-loader span::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  box-shadow: inset 10px 10px 20px rgba(0, 0, 0, 0.5),
              inset -5px -5px 15px rgba(255, 255, 255, 1);
}

.cm-loader span::after {
  content: "";
  position: absolute;
  inset: 40px;
  background: #eaeef0;
  border: 3px solid #eaeef0;
  border-radius: 50%;
  box-shadow: -8px -8px 25px rgba(255, 255, 255, 1),
              8px 8px 25px rgba(0, 0, 0, 0.25),
              inset 3px 3px 10px rgba(0, 0, 0, 0.15),
              inset -1px -1px 15px rgba(255, 255, 255, 1);
}

.cm-loader span i {
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,212,255,1) 35%, rgba(44,99,249,1) 100%);
  animation: animateCMLoader 1s linear infinite;
}

@keyframes animateCMLoader {
  0%
  {
      transform: rotate(0deg);
  }
  100%
  {
      transform: rotate(360deg);
  }
}

.cm-logo {
  position: absolute;
  transform: rotate(45deg);
  left: 10%;
  top: 0;
  right: 58%;
  margin-top: 2px;
  margin-left: 31px;
}


.cm-rocket {
  position: absolute;
  inset: 50px;
  z-index: 10;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}

.cm-rocket .font-rocket {
  position: absolute;
  top: 6%;
  animation: animateCMRocket 0.2s linear infinite;
  margin-left: 31%;
  stroke: #2c63f9;
  fill: rgb(125 211 252);
  stroke-width: 2%;
}

@keyframes animateCMRocket {
  0%,100%
  {
      transform: translate(0,0) rotate(-45deg);
  }
  50%
  {
      transform: translate(0,3px) rotate(-45deg);
  }
}

.cm-rocket .font-cloud {
  position: absolute;
  top: calc(45px * var(--i));
  left: calc(40px * var(--i));
  -webkit-text-stroke: 2px #000;
  animation: animateCMCloud 3.5s linear infinite;
  animation-delay: calc(-0.5s * var(--i));
  stroke: white;
  fill: white;
  stroke-width: 4.5%;
}

@keyframes animateCMCloud {
  0%
  {
      transform: translateY(calc(-35 * 5px));
  }
  100%
  {
      transform: translateY(calc(35 * 5px));
  }
}

.loading-dots {
  clip-path: inset(0 2ch 0 0);
  animation: animateLoadingDots 1.2s steps(4) infinite;
}

@keyframes animateLoadingDots {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

.table-column-code-location {
  min-width: 120px; /* this maintains min width when column is empty, the table column is cut off */
}

/* dark mode fixes */
body[data-theme="dark"] .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab,
body[data-theme="dark"] .ant-modal .ant-card,
body[data-theme="dark"] .ant-modal .ant-table {
  background-color: inherit;
}

body[data-theme="dark"] .ant-card-bordered {
  border-color: #4d5b75;
}

/* table header color fixes */
body[data-theme="dark"] .ant-table-thead th.ant-table-column-sort,
body[data-theme="dark"] .ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: #30394a;
}

/* table body color fixes */
body[data-theme="dark"] td.ant-table-column-sort, 
body[data-theme="dark"] .ant-table-tbody > tr.ant-table-row:hover > td,
body[data-theme="dark"] .ant-table-tbody > tr > td.ant-table-cell-row-hover,
body[data-theme="dark"] .ant-modal .ant-table thead>tr>th,
body[data-theme="dark"] .ant-modal .ant-table td.ant-table-cell-fix-left  {
  background-color: #2b3445;
}

/* the background color is the most similar color to the highchart tooltip background color */
body[data-theme="dark"] .apexcharts-tooltip {
  background-color: #f6f7f8f5 !important;
  color:#353333 !important;
  box-shadow: none !important;
}

/* fix auto complete on login background color */
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

.pulse-container {
  display: flex;
}

.pulse {
  background: #2b63f9;
  box-shadow: 0 0 0 0 rgba(43, 99, 249, 1);
  border-radius: 50%;
  margin: 10px;
  height: 12px;
  width: 12px;
  transform: scale(1);
  animation: pulseKF 2s infinite;
}

@keyframes pulseKF {
  0% {
      transform: scale(0.55);
      box-shadow: 0 0 0 0 rgba(43, 99, 249, 0.7);
  }
  
  70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(43, 99, 249, 0);
  }
  
  100% {
      transform: scale(0.55);
      box-shadow: 0 0 0 0 rgba(43, 99, 249, 0);
  }
}

/* TableDNDColumns component antd table wraper for drag and drop drop area element between columns */
.dnd-line-drop-area {
  border-left: 1px solid #dedede !important;
  width: 15px !important;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 10px) !important;
}

body[data-theme="dark"] .dnd-line-drop-area {
  border-left: 1px dashed #4d5b75 !important;
  width: 15px !important;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(202, 8, 8, 0) 10px) !important;
}
